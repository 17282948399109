import StudentRepository from './modules/studentRepository';
import PaymentRepository from './modules/paymentRepository';

const repositories = {
	student: StudentRepository,
	payment: PaymentRepository
};


export const RepositoryFactory = {
	get: name => repositories[name]
};