const termsCondition = state => state.termsCondition;
const studentInfo = state => state.studentInfo;
const documents = state => state.documents;
const terms = state => state.terms;
const proof_enrollment = state => state.proof_enrollment;
const valid_id = state => state.valid_id;
const proof_payment = state => state.proof_payment;
const portal_status = state => state.portal_status;
const reference_id = state => state.reference_id;
const file = state => state.file;
const nationalities = state => state.nationalities;
const mobile_number = state => state.mobile_number;
const nationality_code = state => state.nationality_code;
const mobile_number_code = state => state.mobile_number_code;

export default {
	termsCondition,
	studentInfo,
	documents,
	terms,
	proof_enrollment,
	valid_id,
	proof_payment,
	portal_status,
	file,
	reference_id,
	nationalities,
	nationality_code,
	mobile_number_code,
	mobile_number
};