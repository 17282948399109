<template>
  <div class="text-center">
    <v-dialog
      :value.sync="isLoading"
      persistent
      width="300"
    >
      <v-progress-linear
        indeterminate
        color="black"
        class="mb-0"
      ></v-progress-linear>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			isLoading: 'app/isLoading'
		})
	},
};
</script>