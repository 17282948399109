/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/details/:data",
      name: "Main",
      component: () => import("./layouts/MainPage.vue"),
    },
    {
      path: "/token-expired",
      name: "Token Expired",
      component: () => import("./components/pages/ExpiredToken.vue"),
    },
    {
      path: "*",
      name: "Not Found Page",
      //redirect: '/login',
      component: () => import("./components/pages/404Page.vue")
    }
  ],
  mode: "history"
  // base: process.env.BASE_URL,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let loggedin = JSON.parse(localStorage.getItem("auth-data"));
//     //let loggedin = Vue.$cookies.get("auth-data");
//     // console.log(Vue.$cookies.get("auth-data"))
//     if (loggedin) {
//       store.dispatch("auth/setCurrUser", loggedin.user);
//       next();
//     } //else window.location = "login";
//   } else {
//     next();
//   }
// });

export default router;
