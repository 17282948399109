import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASE_URL;
console.log(baseDomain)
const baseURL = `${baseDomain}`;

let config = {
	baseURL,
  responseType: 'arraybuffer'
};

let myAPI = axios.create(config);

myAPI.interceptors.request.use(
	(config) => {
		config.headers['Accept'] = 'application/json';
		let token = localStorage.getItem('token');
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	}
);

myAPI.interceptors.response.use(function (response) {
	return response;
});

export default myAPI;