import { RepositoryFactory } from '../../../api_repo/RepositoryFactory';
import router from '@/router';
// import Vue from 'vue';

const studentRepository = RepositoryFactory.get('student');
const paymentRepository = RepositoryFactory.get('payment');

const getStudentInfo= (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		studentRepository.getReferenceId(payload)
			.then((response) => {
				if(response.data.result.StatusCode && response.data.result.StatusCode == '-1'){
					router.push('/token-expired');
				}
				context.commit('SET_STUDENT_INFO', response.data.result[0]);
				context.commit('SET_PORTAL_STATUS', response.data.result[0].portal_status)
				context.commit('SET_FILE', response.data.result[0].proof_of_payment_file_name)
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				router.push('/token-expired');
			});

	}, 1500);
};

const savePayment = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		paymentRepository.savePayment(payload)
			.then((response) => {
				if(response.data.result.StatusCode && response.data.result.StatusCode == '-1'){
					router.push('/token-expired');
				}
				context.commit('app/SET_IS_LOADING', false, { root: true });
				// context.commit('SET_STUDENT_INFO', response.data.result)
				context.commit('SET_PORTAL_STATUS', response.data.result.portal_status)
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};
const updateStatus = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		paymentRepository.updateStatus(payload)
			.then((response) => {
				if(response.data.result.StatusCode && response.data.result.StatusCode == '-1'){
					router.push('/token-expired');
				}
				context.commit('app/SET_IS_LOADING', false, { root: true });
				// context.commit('app/SET_SNACK_BAR',{'status': true,'message':'here','messagetype':'error'}, { root: true });
				// context.commit('SET_STUDENT_INFO', response.data.result)
				context.commit('SET_PORTAL_STATUS', response.data.result.portal_status)
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};
const uploadPayment = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		paymentRepository.uploadPayment(payload)
			.then((response) => {
				if(response.data.result.StatusCode && response.data.result.StatusCode == '-1'){
					router.push('/token-expired');
				}
				context.commit('app/SET_IS_LOADING', false, { root: true });
				// context.commit('app/SET_SNACK_BAR',{'status': true,'message':'here','messagetype':'error'}, { root: true });
				// context.commit('SET_STUDENT_INFO', response.data.result)
				context.commit('SET_PORTAL_STATUS', response.data.result.portal_status)
				context.commit('SET_REFERENCE_ID', response.data.result.reference_id)
				context.commit('SET_FILE', response.data.result.proof_of_payment_file_name)
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const getDocuments = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		paymentRepository.getReferenceId(payload)
			.then((response) => {
				console.log(response);
				context.commit('SET_DOCUMENTS', response.data.result);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};

const login = (context, payload) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		paymentRepository.login(payload)
			.then((response) => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				resolve(response);
			}, error => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
				reject(error);
			});
	});
};

const download = (context) => {
context.commit('app/SET_IS_LOADING', true, { root: true });
	return new Promise((resolve, reject) => {
		paymentRepository.download()
			.then(({ data }) => {
				resolve(data);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			}).catch((err) => {
				reject(err);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});
	});
}
const getCountryList = (context) => {
	context.commit('app/SET_IS_LOADING', true, { root: true });
	setTimeout(() => {
		studentRepository.getCountryList()
			.then((response) => {
				context.commit('SET_COUNTRY', response.data.result);
				context.commit('app/SET_IS_LOADING', false, { root: true });
			})
			.catch(() => {
				context.commit('app/SET_IS_LOADING', false, { root: true });
			});

	}, 1500);
};
export default {
    getCountryList,
    getStudentInfo,
	savePayment,
	getDocuments,
	updateStatus,
	uploadPayment,
	login,
	download
};