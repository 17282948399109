const drawer = state => state.drawer;
const color = state => state.color;
const image = state => state.image;
const shown = state => state.shown;
const message = state => state.message;
const messagetype = state => state.messagetype;
const isLoading = state => state.isLoading;

export default {
	drawer,
	color,
	image,
	shown,
	message,
	messagetype,
	isLoading
};