<template>
    <div>
        <v-app-bar
        id="navbar"
        rounded
        >
          <span class="title appbar-title ml-3 mr-5">
            <v-img src="../../assets/logo.png"/>
          </span>
          <span class="title appbar-title ml-3 mr-5">
            <v-img src="../../assets/CONVERA.svg"/>
          </span>
        </v-app-bar>
    </div>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>

<style>
#navbar {
  background-image: url(../../assets/gradient-background.png);
  /* background-image: url(../../assets/header.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
  border-radius: 10px;
}
</style>

