const SET_TERMS_CONDITION = (state, termsCondition) => {
	state.termsCondition = termsCondition;
};

const SET_STUDENT_INFO = (state, studentInfo) => {
	state.studentInfo = studentInfo;
};

const SET_PORTAL_STATUS = (state, portal_status) => {
	state.portal_status = portal_status;
};


const SET_FILE = (state, file) => {
	state.file = file;
};

const SET_DOCUMENTS = (state, payload) => {
	state.documents = payload;
};

const SET_TERMS = (state, payload) => {
	state.terms = payload;
};

const SET_PROOF_ENROLLMENT = (state, payload) => {
	state.proof_enrollment = payload;
};

const SET_VALID_ID = (state, payload) => {
	state.valid_id = payload;
};

const SET_PROOF_PAYMENT = (state, payload) => {
	state.proof_payment = payload;
};

const SET_REFERENCE_ID = (state, payload) => {
	state.reference_id = payload;
};

const SET_COUNTRY = (state, nationalities) => {
	state.nationalities = nationalities;
};

const SET_NATIONALITY_CODE = (state, payload) => {
	state.nationality_code = payload;
};

const SET_MOBILE_NUMBER = (state, payload) => {
	state.mobile_number = payload;
};

const SET_MOBILE_NUMBER_CODE = (state, payload) => {
	state.mobile_number_code = payload;
};

export default {
	SET_TERMS_CONDITION,
	SET_STUDENT_INFO,
	SET_DOCUMENTS,
	SET_TERMS,
	SET_PROOF_ENROLLMENT,
	SET_VALID_ID,
	SET_PROOF_PAYMENT,
	SET_PORTAL_STATUS,
	SET_FILE,
	SET_REFERENCE_ID,
	SET_COUNTRY,
	SET_MOBILE_NUMBER_CODE,
	SET_MOBILE_NUMBER,
	SET_NATIONALITY_CODE
};