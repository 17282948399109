<template>
  <v-app>
    <v-main>
      <Navigation/>
      <AppSnackBar/>
      <AppLoader/>
      <br>
			<router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from './components/partials/Navigation.vue';
import AppSnackBar from './components/partials/AppSnackBar.vue';
import AppLoader from './components/partials/AppLoader.vue';
// import MainPage from './layouts/MainPage.vue';

export default {
  name: 'App',

  components: {
    Navigation,
    AppSnackBar,
    AppLoader,
    // MainPage
  },

  data: () => ({
    //
  }),
};
</script>

<style>
#app {
  font-family: Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 20px;
  /* font-size:2rem; */
  /* line-height: 2vw; */
}

#inspire {
    margin-left: 10%;
    margin-right: 10%;
}

button {
  color: red;
  margin: 5px;
}

h1, h2, h3, h4, h5, h6 {
  color: #4C6A99;
  padding-top: 15px;
  padding-bottom: 15px;
}
.layout {
  padding-top: 15px;
  padding-bottom: 15px;
}
p {
  color:#606266;
  margin-bottom: 0;
  font-size: 14px;
}

li {
  margin-bottom: 0%;
}

input[type="radio"] {
  margin-right: 10px;
  margin-left: 10px;
}

a {
  font-size: 14px;
  line-height: 28px;
  text-decoration-line: underline;
  color: rgba(77, 77, 77, 0.87);
}
.v-application p {
    margin-bottom: 0px; 
}

@media screen and (max-width: 500px) {
#app {
  font-family: Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin: 20px; */
}

#inspire {
    margin-left: 10px;
    margin-right: 10px;
}

p {
  color:#606266;
  margin-bottom: 0;
  font-size: .7rem;
}


a {
  font-size: .7rem;
  line-height: 28px;
  text-decoration-line: underline;
  color: rgba(77, 77, 77, 0.87);
}

button {
  color: red;
  margin: 5px;
}

h1, h2, h3, h4, h5, h6 {
  color: #4C6A99;
}
.layout {
  padding-top: 15px;
  padding-bottom: 15px;
}
input[type="radio"] {
  margin-right: 10px;
  margin-left: 10px;
}
} 
</style>
