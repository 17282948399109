import Repository from '../Repository';
import RepositoryDownload from '../RepositoryDownload';

const resource = '/reference-payment';
const reference = '/reference-payment-id';
const status = '/student-portal';
const payment = '/reference-payment-upload';
const login = '/studentPortalLogin';
const download = '/student-generate-pdf';

export default {

	get() {
		return Repository.get(`${resource}`);
	},
	savePayment(payload) {
		return Repository.post(`${resource}`, 
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
	},
	getReferenceId() {
		return Repository.get(`${reference}`);
	},
	updateStatus(payload) {
		return Repository.post(`${status}`, payload);
	},
	uploadPayment(payload) {
		return Repository.post(`${payment}`, payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
	},
	login(payload) {
		return Repository.post(`${login}`, payload);
	},
	download() {
		return RepositoryDownload.get(`${download}`)
	}
};


