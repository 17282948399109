<template>
  <v-snackbar :value.sync="shown" :color="type" multi-line bottom >
	{{message}}
    <template v-slot:action="{ attrs }">
		<v-btn icon @click="close" x-small v-bind="attrs">
			<v-icon>mdi-window-close</v-icon>
		</v-btn>
	</template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name : "AppSnackBar",
	computed: {
		...mapGetters({
			message: 'app/message',
			shown: 'app/shown',
			type: 'app/messagetype'
		})
	},
	watch:{
		shown(newVal){
			if(newVal){
				setTimeout(() => {
					this.close();
				}, 10000);
			}
		}
	},
	methods: {
		close() {
			this.$store.dispatch('app/setSnackBar',{'status': false,'message':'','messagetype':''});
		}
	}
};
</script>