export default{
	studentInfo : {},
	documents: {},
	terms: false,
	valid_id : [],
	proof_enrollment: [],
	proof_payment: [],
	portal_status: 0,
	reference_id: '',
	file: '',
	mobile_number: '',
	nationality_code: [],
	mobile_number_code: '0',
	nationalities: []
};