import Repository from '../Repository';

const resource = '/student-reference-id';
const country = '/country';

export default {

	getReferenceId() {
		return Repository.get(`${resource}`);
	},
	getCountryList() {
		return Repository.get(`${country}`);
	}
};


